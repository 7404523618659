.banner__textcontent {
  letter-spacing: 1px;
  font-size: 1.7vw;
  line-height: 1.4;
  color: white;
  text-shadow: -0.07vw -0.07vw 0 #ff9100, 0.07vw -0.07vw 0 #ff9100, -0.07vw 0.07vw 0 #ff9100, 0.07vw 0.07vw 0 #ff9100;
  padding-top: 20px;
  margin: auto;
  text-align: center;
}

.banner {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.banner__container{
  max-width: 100%;
  padding-bottom: 5vh;
}
