.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 5vh;
}

.left {
  flex: 55%;
  padding-left: 1vw;
  padding-right: 2vw;
}

.left img {
  max-width: 100%;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 45%;
  color: white;
  padding-left: 0px;
  padding-right: 1vw;
}

.right__title {
  font-size: 3vw;
  color: white;
  text-shadow: -0.07vw -0.07vw 0 #ff9100, 0.07vw -0.07vw 0 #ff9100, -0.07vw 0.07vw 0 #ff9100, 0.07vw 0.07vw 0 #ff9100;
  padding-bottom: 5vh;
}

.right__content {
  font-size: 1.5vw;
  line-height: 1.4;
  color: #D3D3D3;
}

.readmore__button {
  background-color: #ff9100;
  border-radius: 30px;
  color: white;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 14px 35px;
  text-decoration: none;
  margin-top: 10px;
}
