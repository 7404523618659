.student {
  flex: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 1vw;
  margin-left: 1vw;
  max-width: 25%;
  position: relative;
}

.student img {
  max-height: 100%;
  width: 68%;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.info__container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 1vh;
  text-align: center;
}

.director__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.student__name {
  letter-spacing: 0.05vw;
  font-size: 1.4vw;
  color: #f7f9fb;
  margin-bottom: 0.9vh;
  font-weight: 400;
}

.student__level {
  letter-spacing: 0.05vw;
  font-size: 1.1vw;
  color: #ff9100;
  font-weight: 400;
  margin-bottom: 0.7vh;
}

.student__director {
  font-size: 0.9vw;
  line-height: 1.4;
  color: #d3d3d3;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.student__info {
  font-size: 1vw;
  line-height: 1.4;
  color: #d3d3d3;
  text-align: justify;
  margin-top: 1vh;
}

.toggle-info__button {
  border-color: #f7f9fb;
  border-radius: 10px;
  background: none;
  color: #d3d3d3;
  font-size: 1vw;
}

.toggle-info__button:hover {
  color: #ff9100;
}

.overlay{
  position: absolute;
  background: rgba(29, 39, 49, 0.5);
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 100%;
  height: 0;
  width: 100%;
  overflow: hidden;
  backdrop-filter: blur(8px) brightness(80%);
  transition: all .3s ease-in-out;
}

.show-overlay{
  bottom: 0;
  height: 100%;
  color: #f7f9fb;
  font-size: 1.05vw;
}

.close-button{
  position: absolute;
  margin-bottom: 90%;
  left: 90%;
  color: #ff9100;
  background-color: #1d2731;
  cursor: pointer;
}