.logos__container {
  display: flex;
  flex-direction: row;
  margin-bottom: 3vh;
}

.logo__item {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding-left: 0.05vw;
  padding-right: 0.05vw;
}

.logo__item img {
  max-width: 100%;
  max-height: 100%;
  height: 8vh;
  vertical-align: center;
}
