.pi__container {
  flex: 50%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 5vh;
}

.pi__container img {
  width: 15%;
  aspect-ratio: 1;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pi-info__container {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}

.pi__name {
  letter-spacing: 0.05vw;
  font-size: 1.5vw;
  color: #f7f9fb;
  font-weight: 400;
  margin-bottom: 1vh;
}

.pi__level {
  letter-spacing: 0.05vw;
  font-size: 1.1vw;
  color: #ff9100;
  font-weight: 400;
  margin-bottom: 1.2vh;
}

.pi__info {
  font-size: 1vw;
  line-height: 1.4;
  color: #8f8f8f;
  text-align: justify;
  margin-top: 1vh;
}

.pi__toggle-info-button {
  border: none;
  background: none;
  color: #8f8f8f;
}

