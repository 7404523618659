.nav__container{
    background-color: #1d2731;
    display: flex;
    top: 0;
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    z-index:1;
    border-bottom: 1px solid #ff9100;
}

.nav-white__container{
    background-color: #f7f9fb;
    position: fixed;
    display: flex;
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    width: 100%;
}

.nav-link__container{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.nav-link-white__container{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.nav-link{
    color: #f7f9fb;
    text-decoration: none;
    font-size: 1.5vw;
}

.nav-link-white{
    color: #8f8f8f;
    text-decoration: none;
    font-size: 1.5vw;
}

.nav-logo{
    color: #ff9100;
    font-weight: bold;
    font-size: 1.8vw;
    text-decoration: none;
}

.nav-link:hover {
    color: #ff9100;
}

.nav__active {
    color: #ff9100;
  }