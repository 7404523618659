.publication-set__container {
  color: white;
}

.button__container {
  text-align: center;
  margin-top: 2.4vh;
  margin-left: auto;
  margin-right: auto;
}

.publication-toggle-info__button {
  border-color: #f7f9fb;
  border-radius: 10px;
  background: none;
  color: #d3d3d3;
  font-size: 1.4vw;
}

.publication-toggle-info__button:hover {
  color: #ff9100;
}
