.member__container {
  display: flex;
  flex-direction: column;
  margin-left: 3vw;
  margin-bottom: 3vw;
  margin-right: 3vw;
  max-width: 50%;
  flex: 50%;
}

.member__container img {
  max-width: 100%;
  max-height: 100%;
  width: 30%;
  aspect-ratio: 1/1;
  /* height: 200px; */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.info__container {
  margin-left: 2vw;
  margin-right: 2vw;
}

.director__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
}

.member__name {
  letter-spacing: 0.05vw;
  font-size: 1.5vw;
  color: #f7f9fb;
  margin-bottom: 0.5vh;
  font-weight: 400;
}

.member__level {
  letter-spacing: 0.05vw;
  font-size: 1.1vw;
  color: #ff9100;
  font-weight: 400;
  margin-bottom: 1.2vh;
}

.member__director {
  font-size: 0.9vw;
  line-height: 1.4;
  color: #d3d3d3;
  text-align: center;
  margin-left: 1vw;
  margin-right: 1vw;
}

.member__info {
  font-size: 1vw;
  line-height: 1.4;
  color: #d3d3d3;
  text-align: justify;
  margin-top: 1vh;
}

.member-toggle-info__button {
  border-color: #f7f9fb;
  border-radius: 10px;
  background: none;
  color: #d3d3d3;
  font-size:1vw;
}

.member-toggle-info__button:hover {
  color: #f7f9fb;
}

.twitter__link, .webpage__link{
  color: #f7f9fb;
  margin-bottom: 1.2vh;
}

.twitter__link:hover, .webpage__link:hover{
  color:tomato;
}


.weblinks__container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  text-align: center;
}


.member-overlay{
  position: absolute;
  background: rgba(29, 39, 49, 0.5);
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 100%;
  height: 0;
  width: 100%;
  overflow: hidden;
  backdrop-filter: blur(8px) brightness(80%);
  transition: all .3s ease-in-out;
}

.show-member-overlay{
  bottom: 0;
  height: 100%;
  color: #f7f9fb;
  font-size: 1.05vw;
}

.member-close-button{
  position: absolute;
  margin-bottom: 90%;
  left: 90%;
  color: #ff9100;
  background-color: #1d2731;
  cursor: pointer;
}