.alumni img {
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.alumni__info-container{
   margin-left: auto;
   margin-right: auto;
   display: block;
   margin-top: 10px;
   text-align: center;
   display: flex;
   flex-direction: column;
   max-width: 250px;
}

.alumni__program, .alumni__title{
    line-height: 1.4;
    color: #8f8f8f;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.alumni__hr{
    border: 1px solid #8f8f8f;
    width: 80%;
}

.alumni__name {
    letter-spacing: 1px;
    font-size: 120%;
    text-shadow: 2px 2px 2px #333;
    color: #ff9100;
    margin-bottom: 5px;
    font-weight: 400;
}

.alumni__thesis{
    color: #8f8f8f;
    margin-bottom: 5px;
    text-decoration:none;
}

.alumni__thesis:hover{
    color: #ff9100;
    text-decoration:underline;
}