.research__container {
  display: flex;
  flex-direction: row;
  margin-top: 6vh;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}

.sr__container,
.ml__container,
.neuro__container {
  flex: 33%;
  display: flex;
  flex-direction: column;
  margin-right: 1vh;
  margin-left: 1vh;
}

.research__image {
  max-width: 80%;
  max-height: 35vh;
  margin-left: auto;
  margin-right: auto;
}

.sr__research-label,
.ml__research-label,
.neuro__research-label {
  text-align: center;
  justify-content: center;
  color: white;
  text-shadow: -0.07vw -0.07vw 0 #ff9100, 0.07vw -0.07vw 0 #ff9100, -0.07vw 0.07vw 0 #ff9100, 0.07vw 0.07vw 0 #ff9100;
  font-size: 1.8vw;
  margin-bottom: 1vh;
  margin-top: 0.5vh;
}

.sr__research-description,
.ml__research-description,
.neuro__research-description {
  color: white;
  line-height: 1.9;
  color: #D3D3D3;
  font-size: 1.5vw;
  text-align: center;
}
