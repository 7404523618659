.contact-us {
  color: white;
  margin-bottom: 3.5vh;
  line-height: 1.9;
  font-size: 1.3vw;
  font-weight: 400;
  text-align: center;
}

.mail,
.twitter {
  color: white;
  display: flex;
  flex-direction: row;
  padding-left: 3vw;
  margin-left: 3vw;
}

.location {
  color: white;
  display: flex;
  flex-direction: row;
  margin-right: 5vw;
  padding-right: 5vw;
}

.scholar {
  margin-right: 5.5vw;
  padding-right: 5.5vw;
}

.mail:hover {
  color: #ff9100;
}

.location:hover {
  color: #ff9100;
}

.scholar:hover {
  color: #ff9100;
}

.mail__icon,
.twitter__icon,
.location__icon {
  margin-right: 1.5vw;
}

.email,
.maps,
.twitter__ref {
  color: white;
  font-size: 1.15vw;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 7px;
  text-decoration: none;
}

.scholar__ref {
  color: white;
  font-size: 1.15vw;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 0.07vh;
  text-decoration: none;
  margin-top: 5vh;
}

.contact__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact__last-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3vh;
}

.scholar_img {
  width: 3.5vw;
  height: 3.5vw;
  vertical-align: bottom;
  margin-right: 1.5vw;
}
