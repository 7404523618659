.app {
  background-color: #1d2731;
}

body {
  background-color: #1d2731;
  box-sizing: border-box;
}

.trailing-students__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 30px;
  margin-bottom: 30px;
  max-width: 50%;
}

.students__label,
.publications__label,
.affiliations__label,
.gallery__label,
.funding__label,
.summerstudents__label{
  text-align: center;
  color: white;
  text-shadow: -0.07vw -0.07vw 0 #ff9100, 0.07vw -0.07vw 0 #ff9100, -0.07vw 0.07vw 0 #ff9100, 0.07vw 0.07vw 0 #ff9100;
  margin-bottom: 4vh;
  font-size: 2.3vw;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10vh;
}

.alumni-label__container{
  margin-top: 5vh;
  text-align: center;
}

.alumni__label{
  text-align: center;
  color: white;
  text-shadow: -0.07vw -0.07vw 0 #ff9100, 0.07vw -0.07vw 0 #ff9100, -0.07vw 0.07vw 0 #ff9100, 0.07vw 0.07vw 0 #ff9100;
  margin-bottom: 5vh;
  font-size: 2.3vw;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 11vh;
  text-decoration: none;
  transition: transform 450ms;
  display: inline-block;
}

.alumni__label:hover{
  color: #ff9100;
  transform: scale(1.28);
  opacity: 1;
}

.members__label {
  text-align: center;
  color: white;
  text-shadow: -0.07vw -0.07vw 0 #ff9100, 0.07vw -0.07vw 0 #ff9100, -0.07vw 0.07vw 0 #ff9100, 0.07vw 0.07vw 0 #ff9100;
  margin-bottom: 4vh;
  font-size: 2.3vw;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 11vh;
}

.contact__label {
  color: white;
  margin-bottom: 4vh;
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.2;
  margin-left: 6vw;
  margin-right: 6vw;
}

hr.contact__hr {
  border: 1px solid white;
  margin-bottom: 4vh;
}

.designed__by {
  color: white;
  margin: auto;
  text-align: center;
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vh;
}

.closing__line {
  width: 60%;
}

.summerstudent-section__container{
  display:flex;
  justify-content: space-evenly;
}